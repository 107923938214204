import axios from 'axios'

import {
  AI_DOWNLOAD_RESULT_DATA, AI_LOGS,
  AI_MODULE_QUERY, AI_MODULE_REPORT, PSC_INDEX
} from './ConstantService'
import { GlobalService } from './GlobalService'
import {UrlService} from "@/services/UrlService";

export const AiService= {
  async getGeneratedAiDataByModule (params) {
    return await axios.post(AI_MODULE_QUERY.replace('{module}', params.module), {
      query : params.query
    }).then(response => {

      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET GENERATED AI DATA BY MODULE SERVICE')
      return false
    })
  },

  async downloadDataResult(query) {
    const response = await axios({
      url:AI_DOWNLOAD_RESULT_DATA,
      method: 'POST',
      responseType: 'blob',
      params : {
        query : query
      }
    });
    if (response.hasOwnProperty('status') && response.status === 200) {
      return response?.data ?? false;
    }
    return false;
  },
  async saveAiKidsLogs(params) {
    return await axios.post(AI_LOGS, params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'ADD AI LOGS SERVICE')
      return false
    })
  },
  async getAiKidsLogs(params) {
    const queryString = UrlService.setQueryString(params)
    return await axios.get(AI_LOGS + queryString).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET AI LOGS SERVICE')
      return false
    })
  },
  async getReportsByUniqueNumber(params) {
    return await axios.post(AI_MODULE_REPORT.replace('{module}', params.module), {
      report_numbers : params.report_numbers
    }).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET AI LOGS SERVICE')
      return false
    })
  }
}

