<script>

export default {
  name: "AskAIButton",
  data() {
    return {
      ai_brain: require('@/assets/ai-brain.svg'),
    }
  },
  methods : {
    openChatBox() {
      $('#ai-chat-generator').modal('show');
      setTimeout(() => {
        $('#user-question-area').focus();
      }, 200);
    }
  },
  computed : {
    displayInModule() {
      const routeNamesToDisplay = [
        'ReportIndex',
        'PscIndex',
      ];

      return routeNamesToDisplay.includes(this.$route.name);
    }
  }
}
</script>

<template>
  <div id="sidePanelButton" class="side-panel-button" @click="openChatBox" v-if="displayInModule">
    <div class="text-white" style="display: flex; align-items: center;">
      <img :src="ai_brain" alt="" style="height: 40px; width: 40px">
      <div>&nbsp;&nbsp;<strong>ASK ELITE AI</strong>  &nbsp;</div>&nbsp;
      <svg height="24px" width="24px" version="1.1" id="star-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
           viewBox="0 0 32 32" xml:space="preserve">
          <g>
            <path d="M12,17c0.8-4.2,1.9-5.3,6.1-6.1c0.5-0.1,0.8-0.5,0.8-1s-0.3-0.9-0.8-1C13.9,8.1,12.8,7,12,2.8C11.9,2.3,11.5,2,11,2
              c-0.5,0-0.9,0.3-1,0.8C9.2,7,8.1,8.1,3.9,8.9C3.5,9,3.1,9.4,3.1,9.9s0.3,0.9,0.8,1c4.2,0.8,5.3,1.9,6.1,6.1c0.1,0.5,0.5,0.8,1,0.8
              S11.9,17.4,12,17z"/>
            <path d="M22,24c-2.8-0.6-3.4-1.2-4-4c-0.1-0.5-0.5-0.8-1-0.8s-0.9,0.3-1,0.8c-0.6,2.8-1.2,3.4-4,4c-0.5,0.1-0.8,0.5-0.8,1
              s0.3,0.9,0.8,1c2.8,0.6,3.4,1.2,4,4c0.1,0.5,0.5,0.8,1,0.8s0.9-0.3,1-0.8c0.6-2.8,1.2-3.4,4-4c0.5-0.1,0.8-0.5,0.8-1
              S22.4,24.1,22,24z"/>
            <path d="M29.2,14c-2.2-0.4-2.7-0.9-3.1-3.1c-0.1-0.5-0.5-0.8-1-0.8c-0.5,0-0.9,0.3-1,0.8c-0.4,2.2-0.9,2.7-3.1,3.1
              c-0.5,0.1-0.8,0.5-0.8,1s0.3,0.9,0.8,1c2.2,0.4,2.7,0.9,3.1,3.1c0.1,0.5,0.5,0.8,1,0.8c0.5,0,0.9-0.3,1-0.8
              c0.4-2.2,0.9-2.7,3.1-3.1c0.5-0.1,0.8-0.5,0.8-1S29.7,14.1,29.2,14z"/>
            <path d="M5.7,22.3C5.4,22,5,21.9,4.6,22.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.3C4,22.7,4,22.9,4,23s0,0.3,0.1,0.4
              c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.2C4.7,24,4.9,24,5,24c0.1,0,0.3,0,0.4-0.1s0.2-0.1,0.3-0.2
              c0.1-0.1,0.2-0.2,0.2-0.3C6,23.3,6,23.1,6,23s0-0.3-0.1-0.4C5.9,22.5,5.8,22.4,5.7,22.3z"/>
            <path d="M28,7c0.3,0,0.5-0.1,0.7-0.3C28.9,6.5,29,6.3,29,6s-0.1-0.5-0.3-0.7c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.5-0.1-0.8,0
              c-0.1,0-0.2,0.1-0.3,0.2C27.1,5.5,27,5.7,27,6c0,0.3,0.1,0.5,0.3,0.7C27.5,6.9,27.7,7,28,7z"/>
          </g>
      </svg>
    </div>

  </div>
</template>

<style scoped>
.side-panel-button {
  position: fixed;
  bottom: 10px;
  right: -155px;
  background-color: white;
  //background: rgb(211,49,60);
  //background: linear-gradient(86deg, rgba(211,49,60,1) 45%, rgba(255,98,0,1) 100%);
  //border: 1px solid red;

  padding: 5px 5px;
  border-radius: 50px 0 0 50px;
  cursor: pointer;
  box-shadow: 20px 24px 20px 24px rgba(0.2, 0.2, 0.2, 0.2);
  transition: background-color 0.3s ease;
  width: 200px;
}


#sidePanelButton:hover {
  transition: 0.5s;
  right: -35px;
}

#star-icon {
  transition: all 1s ease;
  animation: pulse .7s ease;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.22); }
}
</style>
