import {AiService} from "@/services/AiService";
import {KeyService} from "@/services/KeyService";

const state = {
  ai_logs: [],

  ai_accident_incidents : {
    module_visible : false,
    helper_fields :
      {
        report_status : {
          data : [],
          two_questions_with_values : [],
          field_meaning_verification : '',
          field_description : ''
        },
        event_category : {
          data : [],
          two_questions_with_values : [],
          field_meaning_verification : '',
          field_description : ''
        },
        // kobe_status : {
        //   data : [],
        //   two_questions_with_values : [],
        //   field_meaning_verification : '',
        //   field_description : ''
        // },
        // rohq_status : {
        //   data : [],
        //   two_questions_with_values : [],
        //   field_meaning_verification : '',
        //   field_description : ''
        // },
        // stgt_status : {
        //   data : [],
        //   two_questions_with_values : [],
        //   field_meaning_verification : '',
        //   field_description : ''
        // },
        ship_management_company : {
          data : [],
          two_questions_with_values : [],
          field_meaning_verification : '',
          field_description : ''
        },
        management_office_name : {
          data : [],
          two_questions_with_values : [],
          field_meaning_verification : '',
          field_description : ''
        },
        primary_event_code : {
          data : [],
          two_questions_with_values : [],
          field_meaning_verification : '',
          field_description : ''
        },
        // secondary_event_code : {
        //   data : [],
        //   two_questions_with_values : [],
        //   field_meaning_verification : '',
        //   field_description : ''
        // },
        // tertiary_event_code : {
        //   data : [],
        //   two_questions_with_values : [],
        //   field_meaning_verification : '',
        //   field_description : ''
        // },
        substandard_act_cause : {
          data : [],
          two_questions_with_values : [],
          field_meaning_verification : '',
          field_description : ''
        },
        substandard_condition_cause : {
          data : [],
          two_questions_with_values : [],
          field_meaning_verification : '',
          field_description : ''
        },
        incident_root_cause : {
          data : [],
          two_questions_with_values : [],
          field_meaning_verification : '',
          field_description : ''
        },
        personal_human_error : {
          data : [],
          two_questions_with_values : [],
          field_meaning_verification : '',
          field_description : ''
        },
        management_human_error : {
          data : [],
          two_questions_with_values : [],
          field_meaning_verification : '',
          field_description : ''
        },
        third_party_human_error : {
          data : [],
          two_questions_with_values : [],
          field_meaning_verification : '',
          field_description : ''
        },
      }
  },

  ai_port_state_control : {
    module_visible : false,
    helper_fields : []
  }
}

const mutations = {
  SET_MODULE_VISIBLE: (state, module) => {
    state[module].module_visible = true
  },
  SET_AI_KIDS_LOGS: (state, logs) => {
    state.ai_logs = logs
  },
  SET_ACCIDENT_INCIDENT_REPORT_STATUS_DATA(state, data) {
    state.ai_accident_incidents.helper_fields.report_status.data = data.report_status.map(item => item.name.toLowerCase());
    state.ai_accident_incidents.helper_fields.report_status.two_questions_with_values = data.two_questions_with_values;
    state.ai_accident_incidents.helper_fields.report_status.field_meaning_verification = data.field_meaning_verification;
    state.ai_accident_incidents.helper_fields.report_status.field_description = data.field_description;
  },
  SET_ACCIDENT_INCIDENT_EVENT_CATEGORY_DATA(state, data) {
    state.ai_accident_incidents.helper_fields.event_category.data = data.event_category.map(item => item.name.toLowerCase());
    state.ai_accident_incidents.helper_fields.event_category.two_questions_with_values = data.two_questions_with_values;
    state.ai_accident_incidents.helper_fields.event_category.field_meaning_verification = data.field_meaning_verification;
    state.ai_accident_incidents.helper_fields.event_category.field_description = data.field_description;
  },
  SET_ACCIDENT_INCIDENT_KOBE_STATUS_DATA(state, data) {
    state.ai_accident_incidents.helper_fields.kobe_status.data = data.kobe_status;
    state.ai_accident_incidents.helper_fields.kobe_status.two_questions_with_values = data.two_questions_with_values;
    state.ai_accident_incidents.helper_fields.kobe_status.field_meaning_verification = data.field_meaning_verification;
    state.ai_accident_incidents.helper_fields.kobe_status.field_description = data.field_description;
  },
  SET_ACCIDENT_INCIDENT_ROHQ_STATUS_DATA(state, data) {
    state.ai_accident_incidents.helper_fields.rohq_status.data = data.rohq_status;
    state.ai_accident_incidents.helper_fields.rohq_status.two_questions_with_values = data.two_questions_with_values;
    state.ai_accident_incidents.helper_fields.rohq_status.field_meaning_verification = data.field_meaning_verification;
    state.ai_accident_incidents.helper_fields.rohq_status.field_description = data.field_description;
  },
  SET_ACCIDENT_INCIDENT_STGT_STATUS_DATA(state, data) {
    state.ai_accident_incidents.helper_fields.stgt_status.data = data.stgt_status;
    state.ai_accident_incidents.helper_fields.stgt_status.two_questions_with_values = data.two_questions_with_values;
    state.ai_accident_incidents.helper_fields.stgt_status.field_meaning_verification = data.field_meaning_verification;
    state.ai_accident_incidents.helper_fields.stgt_status.field_description = data.field_description;
  },
  SET_ACCIDENT_INCIDENT_MANAGEMENT_COMPANY_DATA(state, data) {
    state.ai_accident_incidents.helper_fields.ship_management_company.data = data.ship_management_company.map(item => item.name.toLowerCase());
    state.ai_accident_incidents.helper_fields.ship_management_company.two_questions_with_values = data.two_questions_with_values;
    state.ai_accident_incidents.helper_fields.ship_management_company.field_meaning_verification = data.field_meaning_verification;
    state.ai_accident_incidents.helper_fields.ship_management_company.field_description = data.field_description;
  },
  SET_ACCIDENT_INCIDENT_MANAGEMENT_OFFICE_DATA(state, data) {
    state.ai_accident_incidents.helper_fields.management_office_name.data = data.management_office_name.map(item => item.name.toLowerCase());
    state.ai_accident_incidents.helper_fields.management_office_name.two_questions_with_values = data.two_questions_with_values;
    state.ai_accident_incidents.helper_fields.management_office_name.field_meaning_verification = data.field_meaning_verification;
    state.ai_accident_incidents.helper_fields.management_office_name.field_description = data.field_description;
  },
  SET_ACCIDENT_INCIDENT_PRIMARY_EVENT_CODE_DATA(state, data) {
    state.ai_accident_incidents.helper_fields.primary_event_code.data = data.primary_event_code.map(item => item.name.toLowerCase());
    state.ai_accident_incidents.helper_fields.primary_event_code.two_questions_with_values = data.two_questions_with_values;
    state.ai_accident_incidents.helper_fields.primary_event_code.field_meaning_verification = data.field_meaning_verification;
    state.ai_accident_incidents.helper_fields.primary_event_code.field_description = data.field_description;
  },
  SET_ACCIDENT_INCIDENT_SECONDARY_EVENT_CODE_DATA(state, data) {
    state.ai_accident_incidents.helper_fields.secondary_event_code.data = data.secondary_event_code.map(item => item.name.toLowerCase());
    state.ai_accident_incidents.helper_fields.secondary_event_code.two_questions_with_values = data.two_questions_with_values;
    state.ai_accident_incidents.helper_fields.secondary_event_code.field_meaning_verification = data.field_meaning_verification;
    state.ai_accident_incidents.helper_fields.secondary_event_code.field_description = data.field_description;
  },
  SET_ACCIDENT_INCIDENT_TERTIARY_EVENT_CODE_DATA(state, data) {
    state.ai_accident_incidents.helper_fields.tertiary_event_code.data = data.tertiary_event_code.map(item => item.name.toLowerCase());
    state.ai_accident_incidents.helper_fields.tertiary_event_code.two_questions_with_values = data.two_questions_with_values;
    state.ai_accident_incidents.helper_fields.tertiary_event_code.field_meaning_verification = data.field_meaning_verification;
    state.ai_accident_incidents.helper_fields.tertiary_event_code.field_description = data.field_description;
  },
  SET_ACCIDENT_INCIDENT_IMMEDIATE_CAUSE_ACT_DATA(state, data) {
    state.ai_accident_incidents.helper_fields.substandard_act_cause.data = data.substandard_act_cause.map(item => item.name.toLowerCase());
    state.ai_accident_incidents.helper_fields.substandard_act_cause.two_questions_with_values = data.two_questions_with_values;
    state.ai_accident_incidents.helper_fields.substandard_act_cause.field_meaning_verification = data.field_meaning_verification;
    state.ai_accident_incidents.helper_fields.substandard_act_cause.field_description = data.field_description;
  },
  SET_ACCIDENT_INCIDENT_IMMEDIATE_CAUSE_SUBS_CONDITION_DATA(state, data) {
    state.ai_accident_incidents.helper_fields.substandard_condition_cause.data = data.substandard_condition_cause.map(item => item.name.toLowerCase());
    state.ai_accident_incidents.helper_fields.substandard_condition_cause.two_questions_with_values = data.two_questions_with_values;
    state.ai_accident_incidents.helper_fields.substandard_condition_cause.field_meaning_verification = data.field_meaning_verification;
    state.ai_accident_incidents.helper_fields.substandard_condition_cause.field_description = data.field_description;
  },
  SET_ACCIDENT_INCIDENT_ROOT_CAUSE_DATA(state, data) {
    state.ai_accident_incidents.helper_fields.incident_root_cause.data = data.incident_root_cause.map(item => item.name.toLowerCase());
    state.ai_accident_incidents.helper_fields.incident_root_cause.two_questions_with_values = data.two_questions_with_values;
    state.ai_accident_incidents.helper_fields.incident_root_cause.field_meaning_verification = data.field_meaning_verification;
    state.ai_accident_incidents.helper_fields.incident_root_cause.field_description = data.field_description;
  },
  SET_ACCIDENT_INCIDENT_PERSONAL_HUMAN_ERROR_DATA(state, data) {
    state.ai_accident_incidents.helper_fields.personal_human_error.data = data.personal_human_error.map(item => item.name.toLowerCase());
    state.ai_accident_incidents.helper_fields.personal_human_error.two_questions_with_values = data.two_questions_with_values;
    state.ai_accident_incidents.helper_fields.personal_human_error.field_meaning_verification = data.field_meaning_verification;
    state.ai_accident_incidents.helper_fields.personal_human_error.field_description = data.field_description;
  },
  SET_ACCIDENT_INCIDENT_MANAGEMENT_HUMAN_ERROR_DATA(state, data) {
    state.ai_accident_incidents.helper_fields.management_human_error.data = data.management_human_error.map(item => item.name.toLowerCase());
    state.ai_accident_incidents.helper_fields.management_human_error.two_questions_with_values = data.two_questions_with_values;
    state.ai_accident_incidents.helper_fields.management_human_error.field_meaning_verification = data.field_meaning_verification;
    state.ai_accident_incidents.helper_fields.management_human_error.field_description = data.field_description;
  },
  SET_ACCIDENT_INCIDENT_THIRD_PARTY_HUMAN_ERROR_DATA(state, data) {
    state.ai_accident_incidents.helper_fields.third_party_human_error.data = data.third_party_human_error.map(item => item.name.toLowerCase());
    state.ai_accident_incidents.helper_fields.third_party_human_error.two_questions_with_values = data.two_questions_with_values;
    state.ai_accident_incidents.helper_fields.third_party_human_error.field_meaning_verification = data.field_meaning_verification;
    state.ai_accident_incidents.helper_fields.third_party_human_error.field_description = data.field_description;
  },



}

const actions = {
  async getAiKidsLogs({commit}, params){
    const response= await AiService.getAiKidsLogs(params);
    if(response){
      commit('SET_AI_KIDS_LOGS',response.data)
    }
    return false;
  },
  async getHelperFields({commit, state}, module) {
    if (module === 'ai_accident_incidents') {

      const helper_fields = Object.keys(state.ai_accident_incidents.helper_fields);

      for (let counter = 0; counter < helper_fields.length; counter++) {
        const field = helper_fields[counter];
        if (field === 'report_status') {
          commit('SET_ACCIDENT_INCIDENT_REPORT_STATUS_DATA', {
            report_status : await KeyService.getReportStatusKeys(),
            two_questions_with_values : [
              {
                question : 'Can you give me a list of reports that are still open for 2024?',
                value   : 'open'
              },
              {
                question : 'Provide me a list of reports that are now closed for 2022?',
                value   : 'closed'
              }
            ],
            field_meaning_verification: 'If the term (e.g., "new") appears, verify that it pertains to the field and not other meanings (e.g., "my computer is new" should not return a value for the field).',
            field_description : 'indicates the status of the reports for accident incident such as the report is new, open, etc.'
          })
        } else if (field === 'event_category') {
          commit('SET_ACCIDENT_INCIDENT_EVENT_CATEGORY_DATA', {
            event_category : await KeyService.getEventClassImpactKeys(),
            two_questions_with_values : [
              {
                question : 'Can you give me a list of reports that are still open for 2024 that has event class A?',
                value   : 'a'
              },
              {
                question : 'How many reports there are having event category C?',
                value   : 'c'
              }
            ],
            field_meaning_verification: 'If the term (e.g., "A") appears, verify that it pertains to the field and not other meanings (e.g., "A glass is new or she is a singer" should not return a value for the field).',
            field_description : 'indicates the event class or event category of the reports for accident incident such as the report having category a, b or c.'
          })
        } else if (field === 'kobe_status') {
          commit('SET_ACCIDENT_INCIDENT_KOBE_STATUS_DATA', {
            kobe_status : ['pending', 'discussed', 'reviewing'],
            two_questions_with_values : [
              {
                question : 'Can you give me a list of reports B that are still open for 2024 and has kobe status of pending?',
                value   : 'pending'
              },
              {
                question : 'How many reports there are having reviewing status for kobe?',
                value   : 'reviewing'
              }
            ],
            field_meaning_verification: `If the term (e.g., "pending") appears, verify that it pertains to the field and not other meanings (e.g., "My application to the job is still pending" or "the rohq status is reviewing" or "the stgt status is discussed" should not return a value for the field).`,
            field_description : 'indicates the status of kobe of the reports for accident incident such as the report having kobe status of pending, discussed or reviewing.'
          })
        } else if (field === 'rohq_status') {
          commit('SET_ACCIDENT_INCIDENT_ROHQ_STATUS_DATA', {
            rohq_status : ['pending', 'discussed', 'reviewing'],
            two_questions_with_values : [
              {
                question : 'Can you give me a list of reports B that are still open for 2024 and has rohq status of pending?',
                value   : 'pending'
              },
              {
                question : 'How many reports there are having reviewing status for rohq?',
                value   : 'reviewing'
              }
            ],
            field_meaning_verification: `If the term (e.g., pending) appears, verify that it pertains to the field and not other meanings (e.g., "My application to the job is still pending" or "the kobe status is reviewing" or "the stgt status is discussed" should not return a value for the field).`,
            field_description : 'indicates the status of rohq or "KRBS ROHQ" of the reports for accident incident such as the report having rohq status of pending, discussed or reviewing.'
          })
        } else if (field === 'stgt_status') {
          commit('SET_ACCIDENT_INCIDENT_STGT_STATUS_DATA', {
            stgt_status : ['pending', 'discussed', 'reviewing'],
            two_questions_with_values : [
              {
                question : 'Can you give me a list of reports B that are still open for 2024 and has stgt status of pending?',
                value   : 'pending'
              },
              {
                question : 'How many reports there are having reviewing status for stargate?',
                value   : 'reviewing'
              }
            ],
            field_meaning_verification: `If the term (e.g., "pending") appears, verify that it pertains to the field and not other meanings (e.g., "My application to the job is still pending" or "the kobe status is reviewing" or "the rohq status is discussed" should not return a value for the field).`,
            field_description : 'indicates the status of stgt of the reports for accident incident such as the report having stgt status of pending, discussed or reviewing.'
          })
        } else if (field === 'ship_management_company') {
          commit('SET_ACCIDENT_INCIDENT_MANAGEMENT_COMPANY_DATA', {
            ship_management_company : await KeyService.getMngmtCompanyKeys(),
            two_questions_with_values : [
              {
                question : 'Can you give me a list of reports have management company of stargate?',
                value   : 'stargate shipmanagement gmbh7'
              },
              {
                question : 'How many reports there are having management company of k Line?',
                value   : '"k" line roro bulk ship management co., ltd.'
              }
            ],
            field_meaning_verification: `If the term (e.g., "stargate") appears, verify that it pertains to the field and not other meanings (e.g., "the title of book is stargate" should not return a value for the field).`,
            field_description : 'indicates the ship management company of the reports for accident incident such as the report having management company of stargate shipmanagement gmbh7, etc.'
          })
        } else if (field === 'management_office_name') {
          commit('SET_ACCIDENT_INCIDENT_MANAGEMENT_OFFICE_DATA', {
            management_office_name : await KeyService.getMngmtOfficeKeys(),
            two_questions_with_values : [
              {
                question : 'Can you give me a list of reports have management office of Stargate (ECCD)?',
                value   : 'stargate (eccd)'
              },
              {
                question : 'How many reports there are having management office of TNKC Kobe?',
                value   : 'tnkc kobe'
              }
            ],
            field_meaning_verification: `If the term (e.g., "tnkc kobe") appears, verify that it pertains to the field and not other meanings (e.g., "tnkc kobe is the title of my book" should not return a value for the field).`,
            field_description : 'indicates the management office of the reports for accident incident such as the report having management office named tnkc kobe, etc.'
          })
        } else if (field === 'primary_event_code') {
          commit('SET_ACCIDENT_INCIDENT_PRIMARY_EVENT_CODE_DATA', {
            primary_event_code : await KeyService.getEventOneKeys(),
            two_questions_with_values : [
              {
                question : 'What are the reports related to deck machinery failure?',
                value   : 'deck machinery failure'
              },
              {
                question : 'what is the common root cause for aux. machinery failure?',
                value   : 'aux. machinery failure'
              }
            ],
            field_meaning_verification: `If the term (e.g., "deck machinery failure") appears, verify that it pertains to the field and not other meanings (e.g., "deck machinery failure is the title of my book" should not return a value for the field).`,
            field_description : 'indicates the primary event code of the reports for accident incident such as the report having primary event code deck machinery failure, etc.'
          })
        } else if (field === 'secondary_event_code') {
          commit('SET_ACCIDENT_INCIDENT_SECONDARY_EVENT_CODE_DATA', {
            secondary_event_code : await KeyService.getEventTwoKeys(),
            two_questions_with_values : [
              {
                question : 'What are the reports related to accident of Collision accident that have Minor scale?',
                value   : 'collision accident - minor scale'
              },
              {
                question : 'what is the common root cause for the accident of Sinking?',
                value   : 'sinking'
              }
            ],
            field_meaning_verification: `If the term (e.g., "sinking") appears, verify that it pertains to the field and not other meanings (e.g., "the ice is sinking" should not return a value for the field).`,
            field_description : 'indicates the secondary event code of the reports for accident incident such as the report having secondary event code deck machinery failure, etc.'
          })
        } else if (field === 'tertiary_event_code') {
          commit('SET_ACCIDENT_INCIDENT_TERTIARY_EVENT_CODE_DATA', {
            tertiary_event_code : await KeyService.getEventThreeKeys(),
            two_questions_with_values : [
              {
                question : 'What are the reports related to accident of mistake from Voyage Planning?',
                value   : 'voyage planning mistake'
              },
              {
                question : 'what is the common root cause for the accident of the engine room fire?',
                value   : 'fire (engine room)'
              }
            ],
            field_meaning_verification: `If the term (e.g., "voyage planning mistake") appears, verify that it pertains to the field and not other meanings (e.g., "the title of the book is voyage planning mistake" should not return a value for the field).`,
            field_description : 'indicates the tertiary event code of the reports for accident incident such as the report having tertiary event code voyage planning mistake, etc.'
          })
        } else if (field === 'substandard_act_cause') {
          commit('SET_ACCIDENT_INCIDENT_IMMEDIATE_CAUSE_ACT_DATA', {
            substandard_act_cause : await KeyService.getMainCauseKeys({
              cause_type_id: 2,
              cause_type_class_id: 1
            }),
            two_questions_with_values : [
              {
                question : 'What are the reports related to accident that has incorrect Navigation or Ship Handling?',
                value   : 'incorrect navigation or ship handling'
              },
              {
                question : 'what are the consequences of operating equipment without authority from the vessel?',
                value   : 'operating equipment without authority'
              }
            ],
            field_meaning_verification: `If the term (e.g., "operating equipment without authority") appears, verify that it pertains to the field and not other meanings (e.g., "the title of the book is 'operating equipment without authority'" should not return a value for the field).`,
            field_description : 'indicates the immediate cause of substandard act cause of accident incident such as the report having immediate cause substandard act of incorrect navigation or ship handling, etc.'
          })
        } else if (field === 'substandard_condition_cause') {
          commit('SET_ACCIDENT_INCIDENT_IMMEDIATE_CAUSE_SUBS_CONDITION_DATA', {
            substandard_condition_cause : await KeyService.getMainCauseKeys({
              cause_type_id: 2,
              cause_type_class_id: 2
            }),
            two_questions_with_values : [
              {
                question : 'What are the reports related to accident that Exposure to Chemicals?',
                value   : 'exposure to chemicals'
              },
              {
                question : 'what are the consequences of having an outdated chart?',
                value   : 'outdated charts, publications and other documentation'
              }
            ],
            field_meaning_verification: `If the term (e.g., "exposure to chemicals") appears, verify that it pertains to the field and not other meanings (e.g., "the title of the book is 'exposure to chemicals'" should not return a value for the field).`,
            field_description : 'indicates the immediate cause of substandard condition cause of accident incident such as the report having immediate cause substandard condition of outdated charts, publications and other documentation, etc.'
          })
        } else if (field === 'incident_root_cause') {
          commit('SET_ACCIDENT_INCIDENT_ROOT_CAUSE_DATA', {
            incident_root_cause : await KeyService.getMainCauseKeys({
              cause_type_id: 1,
              cause_type_class_id: 0
            }),
            two_questions_with_values : [
              {
                question : 'What are the reports of accident related to Mental/Psychological Stress?',
                value   : 'mental/psychological stress'
              },
              {
                question : 'what are the consequences of having an inadequate supervision/coaching?',
                value   : 'inadequate supervision/coaching'
              }
            ],
            field_meaning_verification: `If the term (e.g., "mental/psychological stress") appears, verify that it pertains to the field and not other meanings (e.g., "She have a mental stress.'" should not return a value for the field).`,
            field_description : 'indicates the root cause of accident incident such as the report having root cause of inadequate supervision/coaching, etc.'
          })
        } else if (field === 'personal_human_error') {
          commit('SET_ACCIDENT_INCIDENT_PERSONAL_HUMAN_ERROR_DATA', {
            personal_human_error : await KeyService.getCauseErrorPersonalKeys(),
            two_questions_with_values : [
              {
                question : 'What are the reports of accident related to Careless Mistakes?',
                value   : 'careless mistakes'
              },
              {
                question : 'what are the disadvantages of having a strict warning?',
                value   : 'strict warning'
              }
            ],
            field_meaning_verification: `If the term (e.g., "careless mistakes") appears, verify that it pertains to the field and not other meanings (e.g., "the title of the book is 'careless mistakes'.'" should not return a value for the field).`,
            field_description : 'indicates the personal human error of accident incident such as the report having personal human error of strict warning, etc.'
          })
        } else if (field === 'management_human_error') {
          commit('SET_ACCIDENT_INCIDENT_MANAGEMENT_HUMAN_ERROR_DATA', {
            management_human_error : await KeyService.getCauseErrorManningKeys(),
            two_questions_with_values : [
              {
                question : 'What are the reports of accident related to Management Careless Mistakes?',
                value   : 'careless mistakes'
              },
              {
                question : 'what are the disadvantages of having a management strict warning?',
                value   : 'strict warning'
              }
            ],
            field_meaning_verification: `If the term (e.g., "careless mistakes") appears, verify that it pertains to the field and not other meanings (e.g., "the title of the book is 'careless mistakes'" should not return a value for the field).`,
            field_description : 'indicates the management human error of accident incident such as the report having management human error of strict warning, etc.'
          })
        } else if (field === 'third_party_human_error') {
          commit('SET_ACCIDENT_INCIDENT_THIRD_PARTY_HUMAN_ERROR_DATA', {
            third_party_human_error : await KeyService.getCauseErrorOtherKeys(),
            two_questions_with_values : [
              {
                question : 'What are the reports of accident related to Error or Mistakes?',
                value   : 'error/mistake'
              },
              {
                question : 'what are the disadvantages of having a mistake from vessel?',
                value   : 'error/mistake'
              }
            ],
            field_meaning_verification: `If the term (e.g., "error or mistake") appears, verify that it pertains to the field and not other meanings (e.g., "the title of the book is 'error or mistake'" should not return a value for the field).`,
            field_description : 'indicates the third party human error of accident incident such as the report having third party human error of error/mistake, etc.'
          })
        }
      }


    } else {
      // todo module for psc
    }




  }
}

const getters = {
  ai_accident_incidents: (state)=>state.ai_accident_incidents,
  ai_port_state_control: (state)=>state.ai_port_state_control,
  ai_logs: (state)=>state.ai_logs
}

export default {
  state,
  mutations,
  actions,
  getters
}
