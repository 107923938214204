'use strict'


const AIKids = () => import('@/views/AIKids')
const AILogs = () => import('@/components/dashboard/generate-ai/AiLogs')

const Psc = () => import('@/views/Psc')

export const RouteAiKidsSettings = {
  path: 'ai-kids/',
  name: 'AiLogs',
  component : AIKids,
  redirect: 'ai-kids/index',
  children: [
    {
      path: 'index/',
      name: 'AiKidsIndex',
      component: AILogs,
    },
  ]
};
