<template>
  <div id="app">
    <router-view/>
    <AskAIButton :key="'ai_assistant_' + $route.name" v-if="visible"/>
  </div>
</template>

<style lang="scss">

</style>
<script>
import AskAIButton from "@/components/dashboard/generate-ai/AskAIButton.vue";
import {mapGetters} from "vuex";
export default {
  components: {AskAIButton},
  computed : {
    ...mapGetters([
      'ai_accident_incidents',
      'ai_port_state_control'
    ]),
    visible() {
      if (this.$route.name === 'ReportIndex') {
        return this.ai_accident_incidents.module_visible;
      }

      return false;
    },
  }
}

</script>
